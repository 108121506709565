<template>
  <div class="partners-bg">
    <p class="title">{{ $t('Partners.contribution_title') }}</p>
    <span class="txt">{{ $t('Partners.contribution_txt') }}</span>
    <div class="contribution">
      <div class="logo-item" v-for="(item, index) in contributions" :key="index">
        <div class="title-container">
          <p class="one">{{ $t('Partners.table_title1') }}</p>
          <p class="two">{{ $t('Partners.table_title2') }}</p>
          <p class="three">{{ $t('Partners.table_title3') }}</p>
        </div>
        <div class="table-container" v-if="$i18n.locale == 'en'">
          <div class="table-item" v-for="(item, index) in table" :key="index">
            <div class="box">
              <p class="second-layer">{{ $t('Partners.' + item.description) }}</p>
              <div class="image-box">
                <img class="table-logo" :src="item.logos" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="table-container" v-if="$i18n.locale == 'cn'">
          <div class="table-item" v-for="(item, index) in table2" :key="index">
            <div class="box">
              <p class="second-layer">{{ $t('Partners.' + item.description) }}</p>
              <div class="image-box">
                <img class="table-logo" :src="item.logos" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="title">{{ $t('Partners.adviser') }}</p>
    <span class="txt">{{ $t('Partners.adviser_txt') }}</span>
    <div class="blue-banner">
      <p class="blue-title">{{ $t('Partners.blue_title') }}</p>
      <p class="blue-title">{{ $t('Partners.blue2_title') }}</p>
    </div>
    <div class="adviser">
      <div class="adviser-item" v-for="(item, index) in advisers" :key="index">
        <div class="adviser-img-box">
          <img class="adviser-img" :src="item.portrait" alt="" />
        </div>
        <div class="content">
          <div class="txt">
            <p class="">{{ $t('Partners.' + item.unit) }}</p>
            <p class="partner-name">{{ $t('Partners.' + item.name) }}</p>
            <p class="partner-en" v-html="$t('Partners.' + item.en_name)"></p>
            <p class="partner-job">{{ $t('Partners.' + item.advisers_job) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="sports-container">
      <div class="sports-box">
        <div class="box-1">
          <div class="box1-border">
            <p class="box-txt">{{ $t('Partners.box_text') }}</p>
          </div>
        </div>
        <p class="box-img">
          <img class="image-size" src="../assets/images/plan-logo.png" alt="" />
        </p>
      </div>
    </div>

    <p class="title">{{ $t('Partners.coach_units') }}</p>
    <span class="txt">{{ $t('Partners.coach_txt') }}</span>
    <div class="coach-list">
      <p class="title1">{{ $t('Partners.coach2_units') }}</p>
      <div class="coach" v-for="(item, index) in coachs" :key="index">
        <li>
          <span>{{ $t('Partners.' + item.name) }}</span> <span>{{ $t('Partners.' + item.work) }}</span>
        </li>
      </div>
      <p class="title1">{{ $t('Partners.coach3_units') }}</p>

      <p class="box-img">
        <img class="image-size" src="../assets/images/CUHK.png" alt="" />
      </p>

      <div class="coach" v-for="(item, index) in coachs2" :key="index">
        <li>
          <span>{{ $t('Partners.' + item.collaboration) }}</span>
        </li>
      </div>
      <p class="title1">{{ $t('Partners.coach4_units') }}</p>
      <div class="coach">
        <li>
          <span style="white-space: pre-wrap">{{ $t('Partners.coach4_txt') }}</span>
        </li>
        <li>
          <span>{{ $t('Partners.coach5_txt') }}</span>
        </li>
      </div>

      <div class="coach">
        <div class="image-box">
          <img class="table-logo" src="../assets/images/HKASMSS_logo.jpg" alt="" />
        </div>

        <div class="image-box">
          <img class="table-logo" src="https://df7ocie544p6c.cloudfront.net/images/AASFP-logo.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contributions: [
        {
          img: require('../assets/images/hkjcct_logo2.png'),
          txt: 'contribution_jocky',
        },
        /*{
          img: require("../assets/images/Donation_tow.png"),
          txt: "contribution_commission",
        },
        {
          img: require("../assets/images/Donation_three.png"),
          txt: "contribution_swire",
        },*/
      ],
      table: [
        {
          top_title: 'table_title1',
          description: 'top_details1',
          logos: require('../assets/images/hkjcct_logo.png'),
        },
        {
          top_title: 'table_title2',
          description: 'top_details2',
          logos: require('../assets/images/comission_on_child.png'),
        },
        {
          top_title: 'table_title3',
          description: 'top_details3',
          logos: 'https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-en.png',
        },
      ],

      table2: [
        {
          top_title: 'table_title1',
          description: 'top_details1',
          logos: require('../assets/images/hkjcct_logo.png'),
        },
        {
          top_title: 'table_title2',
          description: 'top_details2',
          logos: require('../assets/images/comission_on_child.png'),
        },
        {
          top_title: 'table_title3',
          description: 'top_details3',
          logos: 'https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-cn.png',
        },
      ],
      advisers: [
        {
          portrait: require('../assets/images/adviser_img2.webp'),
          name: 'advisers_name',
          en_name: 'advisers_en',
          advisers_job: 'advisers_work',
          unit: 'unit1',
        },
        {
          portrait: require('../assets/images/adviser_img3.jpg'),
          name: 'advisers5_name',
          en_name: 'advisers5_en',
          advisers_job: 'advisers5_work',
          unit: 'unit2',
        },
        {
          portrait: require('../assets/images/adviser_img4.webp'),
          name: 'advisers2_name',
          en_name: 'advisers2_en',
          advisers_job: 'advisers2_work',
          unit: 'unit3',
        },
        {
          portrait: require('../assets/images/adviser_img1.jpg'),
          name: 'advisers1_name',
          en_name: 'advisers1_en',
          advisers_job: 'advisers1_work',
          unit: 'unit4',
        },
        {
          portrait: require('../assets/images/adviser_img5.jpg'),
          name: 'advisers4_name',
          en_name: 'advisers4_en',
          advisers_job: 'advisers4_work',
          unit: 'unit5',
        },
        {
          portrait: require('../assets/images/adviser_img7.jpg'),
          name: 'advisers6_name',
          en_name: 'advisers6_en',
          advisers_job: 'advisers6_work',
          unit: 'unit6',
        },
        {
          portrait: require('../assets/images/adviser_img6.webp'),
          name: 'advisers7_name',
          en_name: 'advisers7_en',
          advisers_job: 'advisers7_work',
          unit: 'unit7',
        },
      ],
      coachs: [
        {
          name: 'coach1',
          work: 'coach1_work',
        },
        {
          name: 'coach2',
          work: 'coach2_work',
        },
        {
          name: 'coach3',
          work: 'coach3_work',
        },
        {
          name: 'coach4',
          work: 'coach4_work',
        },
      ],
      coachs2: [
        {
          collaboration: 'resources_1',
          collaborations: 'partners_1',
        },
        {
          collaboration: 'resources_2',
          collaborations: 'partners_2',
        },
        {
          collaboration: 'resources_3',
          collaborations: 'partners_3',
        },
        {
          collaboration: 'resources_4',
          collaborations: 'partners_',
        },
      ],
      dialogShow: false,
      dialogForm: {
        names: '',
        list: [],
      },
    }
  },
  methods: {
    mytest(item) {
      this.dialogShow = true
      this.dialogForm.name = item.name
      this.dialogForm.list = item.detail
    },
    disappear() {
      this.dialogShow = false
    },
  },
}
</script>
<style lang="scss" scoped>
.partners-bg {
  width: 90%;
  margin: 50px auto 0 auto;
  text-align: left;
  letter-spacing: 0px;
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 25px;
    width: 100%;

    .one {
      justify-content: center;
      padding: 20px;
      display: flex;
      padding: 10px;
      font-weight: bold;
      width: 32%;
      color: white;
      background: #1c4587;
    }
    .two {
      justify-content: center;
      padding: 20px;
      display: flex;
      padding: 10px;
      font-weight: bold;
      width: 32%;
      color: white;
      background: #f15922;
    }
    .three {
      justify-content: center;
      padding: 20px;
      display: flex;
      padding: 10px;
      font-weight: bold;
      width: 32%;
      color: white;
      background: #1deba3;
    }
  }
  .table-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .table-item {
      width: 100%;
      .box {
        display: flex;
        flex-direction: column;

        .second-layer {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 23px;
          height: 100px;
          padding: 20px;
          text-align: center;
          font-weight: bold;
        }
        .image-box {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          height: 100px;
          text-align: center;
        }
        .table-logo {
          width: 350px;
        }
      }
    }
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #f15922;
    margin: 20px 0;
  }
  .title1 {
    font-size: 32px;
    font-weight: bold;
    color: #f15922;
    margin: 20px 0;
    padding-left: 50px;
  }
  .txt {
    display: block;
    font-size: 24px;
    margin: 20px 0;
  }
  .contribution {
    margin-top: 20px;
    margin-bottom: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .logo-item {
      width: 100%;
      margin: 20px;
      .img-box {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        .contribution-img {
          width: 100%;
        }
      }
      .logo-name {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
      }
    }
  }
  .blue-banner {
    border: solid 3px;
    font-size: 26px;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #1c4587;
    height: 80px;
    margin-top: 80px;
    color: white;
    padding: 5px;
    margin-bottom: 50px;
  }
  .adviser {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;
    .adviser-item {
      width: 45%;
      margin: 20px;
      box-shadow: 2px 2px 8px 2px #efecef;
      display: flex;
      justify-content: space-between;
      .adviser-img-box {
        display: flex;
        width: 35%;
        .adviser-img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: top center;
        }
      }
      .content {
        width: 55%;
        margin: 0 5%;
        .partner-name {
          margin-top: 20px;
          color: #28327b;
          font-size: 26px;
        }
        .partner-en {
          color: #d7ede6;
          margin-top: 5px;
        }
        .partner-job {
          color: rgb(112, 112, 112);
          margin-top: 20px;
          font-size: 20px;
        }
      }
    }
  }
  .sports-container {
    justify-content: center;
    text-align: center;
    .box-1 {
      border-bottom: solid 3px;
      font-size: 30px;
      font-weight: bold;
      padding: 10px;
      color: white;
      background: #e79136;
    }
    .box-img {
      padding: 30px;
      margin-bottom: 50px;
    }
    .image-size {
      width: 350px;
    }
  }

  .coach-list {
    margin-bottom: 70px;
    .coach {
      width: 100%;
      display: flex;
      flex-direction: row;
      li {
        padding-left: 120px;
      }
      span {
        font-size: 22px;
        line-height: 2;
      }
      .table-logo {
        width: 350px;
      }
      .table-logo {
        padding-left: 100px;
        width: 350px;
      }
    }
  }
  .con-box {
    .tit-box {
      display: flex;
      align-items: center;
      margin: 20px 0;
      .fb-tit {
        font-size: 14px;
        font-weight: bold;
        color: #f15922;
        margin-right: 20px;
      }
      .fb-logo {
        width: 85px;
      }
    }
  }
  .con-txt {
    font-size: 14px;
    .fb-txt1 {
      margin-bottom: 10px;
    }
    .link-web {
      color: #337ab7;
      display: inline-block;
      margin-top: 10px;
    }
  }
  .act-box {
    .act-tit {
      text-align: center;
      white-space: pre;
      font-size: 20px;
      font-weight: bold;
      color: #9c0;
    }
    .act-content {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .act-img {
        width: 35%;
      }
      .right-txt {
        width: 62%;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;
        .act-img-list {
          .act-item-img {
            width: 180px;
            margin-right: 10px;
          }
          .act-item-img:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .partners-bg {
    .title {
      font-size: 34px;
    }
    .txt {
      font-size: 28px;
    }
    .contribution {
      margin: 0;
      .logo-item {
        width: auto;
        margin: 40px;
      }
    }
    .adviser {
      .adviser-item {
        width: 100%;
        margin: 20px 0;
        .adviser-img-box {
          width: 45%;
        }
        .content {
          flex-shrink: 0;
          .txt {
            .partner-job {
              font-size: 24px;
            }
          }
        }
      }
    }
    .act-box {
      .act-content {
        flex-wrap: wrap;
        justify-content: center;
        .act-img {
          width: 100%;
        }
        .right-txt {
          width: 100%;
          margin-top: 20px;
          justify-content: center;
          .act-img-list {
            .act-item-img {
              width: 150px;
            }
          }
        }
      }
    }
    .coach-list {
      .coach {
        span {
          font-size: 26px;
        }
      }
    }
  }
}
</style>
